<template>
  <div id="PrivacyPolicy">
    <section class="section">
      <div class="container">
        <h1 class="title">{{ $t("policy.header") }}</h1>
        <div class="columns">
          <div class="column is-3 has-text-left">
            <b-menu>
              <b-menu-list>
                <b-menu-item
                  :active="this.$route.name === 'terms-and-condition'"
                  :label="$t('terms.header')"
                  tag="router-link"
                  :to="{ name: 'terms-and-condition' }"
                />
                <b-menu-item
                  :active="this.$route.name === 'privacy-policy'"
                  :label="$t('policy.header')"
                  tag="router-link"
                  :to="{ name: 'privacy-policy' }"
                />
              </b-menu-list>
            </b-menu>
          </div>
          <!-- PRIVACY POLICY CONTENT -->
          <div v-if="$i18n.locale === 'th'" class="column">
            <section class="section has-text-left">
              <!-- นโยบายคุ้มครองข้อมูลส่วนบุคคล -->
              <p class="title is-4">นโยบายคุ้มครองข้อมูลส่วนบุคคล</p>
              <p>
                บริษัท บลัชชิ่ง เอาล์ สตูดิโอ จำกัด (“บริษัท/เรา”)
                ให้ความสำคัญสูงสุดในการปกป้องข้อมูลส่วนบุคคลของท่าน
                ดังนั้นจึงขอแนะนำให้ท่านทำความเข้าใจนโยบายคุ้มครองข้อมูลส่วนบุคคล
                (privacy policy) นี้
                เนื่องจากนโยบายนี้อธิบายถึงวิธีการที่บริษัทปฏิบัติต่อข้อมูลส่วนบุคคลของท่าน
                เช่น การเก็บรวบรวม การจัดเก็บรักษา การใช้ การเปิดเผย
                รวมถึงสิทธิต่าง ๆ ของท่าน เป็นต้น
                เพื่อให้ท่านได้รับทราบถึงนโยบายในการคุ้มครองข้อมูลส่วนบุคคลของบริษัทจึงประกาศนโยบายส่วนบุคคล
                ดังต่อไปนี้
                <br />
                <br />
                ข้อมูลส่วนบุคคล หมายถึง
                “ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้
                ไม่ว่าทางตรงหรือทางอ้อม
                แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ”
                <br />
                <br />
                ข้อมูลส่วนบุคคลที่เราเก็บรวบรวม
                <br />

                1. ชื่อ – นามสกุล <br />
                2. อายุ <br />
                3. เพศ<br />
                4. อีเมล (E –mail) <br />
                5. ที่อยู่ / สถานที่จัดส่งสินค้า<br />
                6. เบอร์โทรศัพท์
              </p>
              <br />
              <!-- แหล่งที่มาของข้อมูลส่วนบุคคล  -->
              <p class="title is-4">แหล่งที่มาของข้อมูลส่วนบุคคล</p>
              <p>
                เราอาจได้รับข้อมูลส่วนบุคคลของท่านจาก 2 ช่องทาง ดังนี้
                <br />
                <br />
                1. เราได้รับข้อมูลส่วนบุคคลจากท่านโดยตรง
                โดยเราจะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านจากขั้นตอนการให้บริการดังนี้<br />
                <span style="padding-right: 1rem" />1.1
                ขั้นตอนการสมัครใช้บริการกับเรา
                หรือขั้นตอนการยื่นคำร้องขอใช้สิทธิต่างๆ กับเรา <br />
                <span style="padding-right: 1rem" />1.2 จากความสมัครใจของท่าน
                ในการทำแบบสอบถาม (survey) หรือ การโต้ตอบทาง email หรือ
                ช่องทางการ สื่อสารอื่นๆ ระหว่างเราและท่าน <br />
                <span style="padding-right: 1rem" /> 1.3 เก็บจากข้อมูลการใช้
                website ของเราผ่าน browser’s cookies ของท่าน
                <br />
                <br />
                2. เราได้รับข้อมูลส่วนบุคคลของท่านมาจากบุคคลที่สาม ดังต่อไปนี้
                <br />
                <span style="padding-right: 1rem" />2.1 ผู้ให้บริการภายนอก
                <br />
                <span style="padding-right: 1rem" />2.2
                ผู้ขายที่เป็นบุคคลภายนอก<br />
                <span style="padding-right: 1rem" />2.3 เว็บไซต์ต่าง ๆ
                ในสื่อสังคมออนไลน์
              </p>
              <br />
              <!-- การประมวลผลข้อมูลส่วนบุคคล  -->
              <p class="title is-4">การประมวลผลข้อมูลส่วนบุคคล</p>
              <p>
                การจัดเก็บรวบรวมข้อมูลส่วนบุคคลจะกระทำโดยมี วัตถุประสงค์ ขอบเขต
                และใช้วิธีการที่ชอบด้วยกฎหมายและเป็นธรรม
                ในการเก็บรวบรวมและจัดเก็บข้อมูล ตลอดจนเก็บรวบรวม
                และจัดเก็บข้อมูลส่วนบุคคลอย่างจำกัดเพียงเท่าที่จำเป็นแก่การให้บริการ
                หรือบริการด้วยวิธีการทางอิเล็กทรอนิกส์อื่นใดภายใต้วัตถุประสงค์ของบริษัทเท่านั้น
                ทั้งนี้บริษัทจะดำเนินการให้เจ้าของข้อมูล รับรู้ ให้ความยินยอม
                ทางอิเล็กทรอนิกส์ ข้อความสั้น (Short Message Service)
                หรือตามแบบวิธีการของบริษัท
                บริษัทอาจจัดเก็บข้อมูลส่วนบุคคลของท่านซึ่งเกี่ยวกับความสนใจและบริการที่ท่านใช้
                ซึ่งอาจประกอบด้วยเรื่อง เชื้อชาติ ศาสนาหรือปรัชญา
                ข้อมูลสุขภาพของท่าน ข้อมูลชีวภาพ ทุพพลภาพ ความพิการ อัตลักษณ์
                หรือข้อมูลอื่นใด ที่จะเป็นประโยชน์ในการให้บริการ ทั้งนี้
                การดำเนินการดังกล่าวข้างต้น
                บริษัทจะขอความยินยอมจากท่านก่อนทำการเก็บรวบรวม เว้นแต่
                <br />
                <br />

                1. เป็นการปฏิบัติตามกฎหมาย เช่น
                พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                พระราชบัญญัติว่าด้วยธุรกรรมทางอิเล็กทรอนิกส์
                พระราชบัญญัติการประกอบกิจการโทรคมนาคม
                พระราชบัญญัติป้องกันและปราบปรามการฟอกเงิน
                ประมวลกฎหมายแพ่งและอาญา ประมวลกฎหมายวิธีพิจารณาความแพ่งและอาญา
                เป็นต้น <br />

                2. เพื่อประโยชน์ของท่าน
                และการขอความยินยอมไม่อาจกระทำได้ในเวลานั้น <br />

                3. เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัท
                หรือของบุคคลหรือนิติบุคคลอื่นที่ไม่ใช่บริษัท <br />

                4. เป็นการจำเป็นเพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
                หรือสุขภาพของบุคคล <br />

                5.
                เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งเจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญาหรือเพื่อใช้ในการดำเนินการตามคำขอของเจ้าของข้อมูลส่วนบุคคลก่อนเข้าทำสัญญานั้น
                <br />

                6.
                เพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวกับการจัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุ
                เพื่อประโยชน์สาธารณะ หรือเพื่อการศึกษา วิจัย การจัดทำสถิติ
                ซึ่งได้จัดให้มีมาตรการป้องกันที่เหมาะสม
              </p>
              <br />
              <!-- การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล -->
              <p class="title is-4">
                การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล
              </p>
              <p>
                เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในระยะเวลาเท่าที่จำเป็นอย่างสมเหตุสมผล
                เพื่อให้บรรลุตามวัตถุประสงค์ที่เราได้รับข้อมูลส่วนบุคคลนั้นมา
                และเพื่อปฏิบัติตามภาระหน้าที่ทางกฎหมายและกฎข้อบังคับต่าง ๆ
                อย่างไรก็ตาม
                เราอาจเก็บข้อมูลส่วนบุคคลของท่านนานขึ้นหากจำเป็นตามกฎหมายที่ใช้บังคับ
              </p>
              <br />
              <!-- การโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ -->
              <p class="title is-4">
                การโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ
              </p>
              <p>
                เราอาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคลภายนอก
                หรือผู้ให้บริการที่อยู่ในต่างประเทศซึ่งประเทศปลายทางอาจมี
                หรืออาจไม่มีระดับการคุ้มครองข้อมูลส่วนบุคคลที่เท่าเทียมกัน
                ทั้งนี้ เราจะดำเนินการตามขั้นตอนและมาตรการต่าง ๆ
                เพื่อทำให้ท่านมั่นใจได้ว่าการโอนข้อมูลส่วนบุคคลของท่านจะโอนอย่างปลอดภัยและบุคคลที่รับโอนข้อมูลนั้นมีระดับการคุ้มครองข้อมูลส่วนบุคคลที่เหมาะสมหรือกรณีอื่น
                ๆ ตามที่กฎหมายกำหนด
                และเราจะขอความยินยอมจากท่านเพื่อการโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศหากจำเป็นต้องปฏิบัติตามที่กฎหมายกำหนด
              </p>
              <br />
              <!-- ความปลอดภัยของข้อมูล -->
              <p class="title is-4">
                ความปลอดภัยของข้อมูล
              </p>
              <p>
                เรารับรองว่าข้อมูลที่จัดเก็บทั้งหมดจะถูกจัดเก็บอย่างปลอดภัย
                เราป้องกันข้อมูลส่วนตัวของท่านโดย
                จำกัดการเข้าถึงข้อมูลส่วนตัว<br />
                จัดให้มีวิธีการทางเทคโนโลยีเพื่อป้องกันไม่ให้มีการเข้าสู่ระบบคอมพิวเตอร์ที่ไม่ได้รับอนุญาต
                จัดการทำลายข้อมูลส่วนตัวของท่านเพื่อความปลอดภัยเมื่อข้อมูลดังกล่าวไม่จำเป็นสำหรับวัตถุประสงค์ทางกฎหมายและธุรกิจอีกต่อไป<br />
                เราใช้เทคโนโลยีการเข้ารหัสชนิด 256 บิท เอสเอสเอล (Security
                Sockets Layer) เมื่อทำการดำเนินการเกี่ยวกับรายละเอียดทางการเงิน
                เทคโนโลยีการเข้ารหัสชนิด 256 บิท เอสเอสเอล
                ได้มีการคาดการว่าต้องใช้เวลา 2 ล้านล้านปีเพื่อเจาะรหัส
                และเป็นมาตรฐานของอุตสาหกรรมด้วย
                <br />
                <br />
                หากท่านมีเหตุให้เชื่อว่าความเป็นส่วนตัวของท่านได้ถูกละเมิดโดยเรา
                กรุณาติดต่อเราผ่านทางอีเมล์ที่ระบุไว้ข้างล่างนี้
                <br />
                <br />
                รหัสผ่านของท่านเป็นกุญแจสำหรับบัญชีการใช้บริการของท่าน
                กรุณาใช้ตัวเลข ตัวอักษรหรือสัญลักษณ์ที่แตกต่างกัน
                และไม่แบ่งปันรหัสผ่านเราแก่ผู้อื่น
                หากท่านได้แบ่งปันรหัสผ่านแก่ผู้อื่น
                ท่านจะรับผิดชอบต่อการกระทำต่างๆ
                ที่ได้กระทำลงในนามหรือผ่านทางบัญชีการใช้บริการของท่านและผลที่ตามมา
                หากท่านไม่สามารถควบคุมรหัสผ่านของท่านได้
                ท่านอาจไม่สามารถควบคุมข้อมูลส่วนตัวของท่านหรือข้อมูลอื่นๆ
                ที่ส่งให้กับเรา ท่านอาจต้องยอมรับนิติกรรมใดๆ ที่ทำลงในนามของท่าน
                ดังนั้นหากรหัสผ่านของท่านถูกเปิดเผยหรือไม่เป็นความลับอีกต่อไปไม่ว่าด้วยเหตุผลใดๆ
                หรือท่านมีเหตุที่อาจเชื่อได้ว่ารหัสผ่านนั้นถูกเปิดเผยหรือไม่เป็นความลับอีกต่อไป
                ท่านควรที่จะติดต่อเราและเปลี่ยนรหัสผ่านของท่าน
                เราขอเตือนให้ท่านออกจากระบบบัญชีการใช้บริการ (log off)
                และปิดบราวเซอร์ (Browser) ทุกครั้งที่ท่านใช้คอมพิวเตอร์สาธารณะ
              </p>
              <br />
              <!-- สิทธิของเจ้าของข้อมูล  -->
              <p class="title is-4">
                สิทธิของเจ้าของข้อมูล
              </p>
              <p>
                ท่านมีสิทธิในการดำเนินการ ดังต่อไปนี้<br />
                (1) สิทธิในการเพิกถอนความยินยอม (right to withdraw consent):
                ท่านมีสิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับเราได้
                ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับเรา <br />
                (2) สิทธิในการเข้าถึงข้อมูลส่วนบุคคล (right of access):
                ท่านมีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลของท่านและขอให้เราทำสำเนาข้อมูลส่วนบุคคลดังกล่าวให้แก่ท่าน
                รวมถึงขอให้เราเปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ท่านไม่ได้ให้ความยินยอมต่อเราได้
                <br />
                (3) สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (right to
                rectification): ท่านมีสิทธิในการขอให้เราแก้ไขข้อมูลที่ไม่ถูกต้อง
                หรือ เพิ่มเติมข้อมูลที่ไม่สมบูรณ์ <br />
                (4) สิทธิในการลบข้อมูลส่วนบุคคล (right to erasure):
                ท่านมีสิทธิในการขอให้เราทำการลบข้อมูลของท่านด้วยเหตุบางประการได้
                <br />
                (5) สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล (right to restriction
                of processing):
                ท่านมีสิทธิในการระงับการใช้ข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้
                <br />
                (6) สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล (right to data
                portability):
                ท่านมีสิทธิในการโอนย้ายข้อมูลส่วนบุคคลของท่านที่ท่านให้ไว้กับเราไปยังผู้ควบคุมข้อมูลรายอื่น
                หรือ ตัวท่านเองด้วยเหตุบางประการได้ <br />
                (7) สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (right to
                object):
                ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้
              </p>
              <br />
              <!-- การเพิกถอนความยินยอม -->
              <p class="title is-4">
                การเพิกถอนความยินยอม
              </p>
              <p>
                ท่านอาจส่งคำคัดค้านการใช้หรือการเปิดเผยข้อมูลส่วนตัวของท่านสำหรับกิจการต่างๆ
                หรือเพื่อวัตถุประสงค์ที่ระบุไว้ด้านบน แก่เราได้ตลอดเวลา
                โดยติดต่อเราผ่านทางอีเมล์ที่ระบุไว้ด้านล่างนี้
                โปรดทราบว่าหากท่านได้ส่งคำคัดค้านการใช้หรือการเปิดเผยข้อมูลส่วนตัวของท่านสำหรับกิจการต่างๆ
                หรือเพื่อวัตถุประสงค์ที่ระบุไว้ด้านบน
                ขึ้นอยู่กับพฤติการณ์ของคำคัดค้าน
                เราอาจไม่อยู่ในสถานะที่จะจัดส่งสินค้าหรือบริการให้แก่ท่านหรือปฏิบัติตามสัญญาต่อท่านได้
                เราขอสงวนสิทธิ์ทางกฎหมายและการเยียวยาไว้โดยชัดแจ้งในกรณีซึ่งเราไม่สามารถจัดส่งสินค้าหรือบริการให้แก่ท่านหรือตามปฏิบัติตามสัญญาต่อท่านได้อันเกิดจากคำคัดค้านดังกล่าว
              </p>
              <br />
              <!-- การอัพเดทหรือปรับเปลี่ยนข้อมูลส่วนตัวของท่าน -->
              <p class="title is-4">
                การอัพเดทหรือปรับเปลี่ยนข้อมูลส่วนตัวของท่าน
              </p>
              <p>
                ท่านสามารถอัพเดทหรือปรับเปลี่ยนข้อมูลส่วนตัวของท่านได้ตลอดเวลาโดยการเข้าสู่บัญชีการใช้บริการของท่านบนแพลตฟอร์มอีคอมเมอร์สของเรา
                หากท่านไม่มีบัญชีการใช้บริการกับเราท่านอาจทำการอัพเดทหรือปรับเปลี่ยนข้อมูลส่วนตัวได้โดยการติดต่อเราผ่านทางอีเมล์ที่ระบุไว้ข้างใต้นี้
              </p>
              <br />
              <!-- คุกกี้ (Cookies) -->
              <p class="title is-4">
                คุกกี้ (Cookies)
              </p>
              <p>
                เรา หรือผู้ให้บริการที่ได้รับอนุญาตอาจจะใช้โปรแกรมคุกกี้
                (cookies) เว็บบีคอน (web beacons) หรือโปรแกรมอื่นๆ
                ที่คล้ายคลึงกันเพื่อจัดเก็บข้อมูล
                เพื่อช่วยให้เราสามารถจัดให้มีการบริการที่ดีขึ้น เร็วขึ้น
                ปลอดภัยขึ้น และเพื่อความเป็นส่วนตัวของท่าน เมื่อท่านใช้บริการ
                และ/หรือเข้าสู่แพลตฟอร์มอีคอมเมอร์ส
                เมื่อท่านเข้าเยี่ยมชมเซิร์ฟเวอร์ (Servers)
                เราทำการจดจำและบันทึกข้อมูลที่บราวเซอร์ของท่านส่งเข้ามาโดยอัตโนมัติเมื่อท่านเข้าเว็บไซต์ของเรา
                ข้อมูลเหล่านี้อาจหมายรวมถึง ที่อยู่ ไอพี แอดเดรส (IP address)
                ของคอมพิวเตอร์ของท่าน ชนิดของบราวเซอร์ของท่าน
                หน้าเว็บไซต์ที่ท่านเข้าถึงก่อนที่ท่านจะเข้าสู่แพลตฟอร์มอีคอมเมอร์ส
                หน้าเว็บไซต์ที่ท่านเข้าชมในแพลตฟอร์มอีคอมเมอร์ส
                จำนวนเวลาที่ท่านใช้ในการชมหน้าเว็บไซต์ดังกล่าว
                สินค้าหรือข้อมูลที่ท่านค้นหาในแพลตฟอร์มอีคอมเมอร์ส
                เวลาเข้าชมวันที่ และข้อมูลทางสถิติอื่นๆ ข้อมูลต่างๆ เหล่านี้
                จะถูกจัดเก็บเพื่อการวิเคราะห์และประเมินเพื่อช่วยให้เราพัฒนาแพลตฟอร์มอีคอมเมอร์ส
                การบริการและสินค้าที่เราจัดหา
                เพื่อสามารถให้บริการท่านได้ดียิ่งขึ้น
                <br />
                <br />
                โปรแกรมคุกกี้ (Cookies) เป็นข้อมูลไฟล์ตัวอักษรขนาดเล็ก
                (โดยปกติจะมีเพียงตัวอักษรและตัวเลข)
                ที่จัดวางไว้บนหน่วยความจำของบราวเซอร์ของท่านหรือบนอุปกรณ์ของท่านเมื่อท่านเข้าชมเว็บไซต์หรือเรียกดูข้อความ
                โปรแกรมนี้ช่วยให้เราจดจำอุปกรณ์หรือบราวเซอร์โดยจำเพาะได้
                และช่วยให้เราสามารถจัดทำเนื้อหาให้เหมาะสมกับความสนใจส่วนบุคคลของท่านได้รวดเร็วขึ้น
                และช่วยให้การบริการและแพลตฟอร์มอีคอมเมอร์สสะดวกสบายและเป็นประโยชน์ต่อท่านมากขึ้น
                <br />
                <br />
                โปรแกรมเว็บ บีคอน (Web beacons)
                เป็นรูปภาพกราฟฟิคขนาดเล็กที่อาจรวมอยู่ในการบริการหรือบนแพลตฟอร์มอีคอมเมอร์ส
                โปรแกรมนี้ช่วยให้เรานับจำนวนผู้ใช้ที่ได้เข้าชมหน้าเว็บไซต์ต่างๆ
                เพื่อให้เราได้เข้าใจความต้องการและความสนใจของท่านมากขึ้น
              </p>
              <br />
              <!-- นโยบายคุ้มครองข้อมูลส่วนบุคคลของ website อื่น -->
              <p class="title is-4">
                นโยบายคุ้มครองข้อมูลส่วนบุคคลของ website อื่น
              </p>
              <p>
                นโยบายความเป็นส่วนตัวฉบับนี้
                ใช้เฉพาะสำหรับการให้บริการของเราและการใช้งาน website
                ของเราเท่านั้น หากท่านได้กด link ไปยัง website อื่น
                (แม้จะผ่านช่องทางใน website ของเราก็ตาม)
                ท่านจะต้องศึกษาและปฏิบัติตามนโยบายความเป็นส่วนตัวที่ปรากฏใน
                website นั้นๆ แยกต่างหากจากของเรา
              </p>
              <br />
              <!-- การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล  -->
              <p class="title is-4">
                การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล
              </p>
              <p>
                เราจะทำการพิจารณาทบทวนนโยบายความเป็นส่วนตัวเป็นประจำเพื่อให้สอดคล้องกับแนวปฏิบัติ
                และ กฎหมาย ข้อบังคับที่เกี่ยวข้อง ทั้งนี้
                หากมีการเปลี่ยนแปลงนโยบายความเป็นส่วนตัว
                เราจะแจ้งให้ท่านทราบด้วยการ update ข้อมูลลงใน website
                ของเราโดยเร็วที่สุด ปัจจุบัน
                นโยบายความเป็นส่วนตัวถูกทบทวนครั้งล่าสุดเมื่อวันที่ 1 มิถุนายน
                2563
              </p>
              <br />
              <!-- ช่องทาง  -->
              <p class="title is-4">
                ช่องทาง
              </p>
              <p>
                Blushing Owl Studio Co.,Ltd <br />
                18/93 ซ.คู้บอน 27 ถ.คู้บอน แขวงท่าแร้ง เขตบางเขน กรุงเทพ 10220
                <br />
                โทร. (+66)84-347-0099 <br />
                Line ID : BlushingOwlStudio <br />
                WeChat : BlushingOwlStudio <br />
                Instagram : BlushingOwlStudio <br />
                Facebook : Blushing Owl Studio <br />
                Email : admin@blushingowlstudio.com <br />
                Website : www.blushingowlstudio.com
              </p>
            </section>
          </div>
          <div v-else class="column">
            <section class="section has-text-left">
              <!-- นโยบายคุ้มครองข้อมูลส่วนบุคคล -->
              <p class="title is-4">Personal Information Protection Policy</p>
              <p>
                Blushing Owl Studio Company Limited ("Company/Us") gives the
                utmost importance to protect your personal information.
                Therefore, it is recommended that you understand this privacy
                policy because this policy describes how the company treats your
                personal information, such as collection Keeping, using,
                disclosing, including your rights. In order to keep you informed
                of our privacy policy, the following privacy policies are
                announced. Personal information means "information about a
                person that makes it possible to identify that person whether
                directly or indirectly But does not include information about
                the deceased, especially ".
                <br />
                <br />
                Personal information we collect
                <br />

                1. First name - Last name <br />
                2. Age <br />
                3. Sex <br />
                4. E-mail Address <br />
                5. Address/delivering address <br />
                6. Telephone number
              </p>
              <br />

              <!-- แหล่งที่มาของข้อมูลส่วนบุคคล  -->
              <p class="title is-4">Sources of Personal Information</p>
              <p>
                We may receive your personal information from 2 channels as
                follows
                <br />
                <br />
                1. We have received personal information directly from you. We
                will collect your personal information from the service process
                as follows:<br />
                <span style="padding-right: 1rem" />1.1 Steps to apply for
                service with us or the process of filing a request for rights
                with us. <br />
                <span style="padding-right: 1rem" />1.2 From your choice in
                conducting surveys or email correspondence or other
                communication channels between us and you.
                <br />
                <span style="padding-right: 1rem" /> 1.3 Collected from our
                website usage data through your browser’s cookies.
                <br />
                <br />
                2. We have received your personal information from the following
                third parties:
                <br />
                <span style="padding-right: 1rem" />2.1 External service
                provider
                <br />
                <span style="padding-right: 1rem" />2.2 Third-party sellers
                <br />
                <span style="padding-right: 1rem" />2.3 Social media websites
              </p>
              <br />
              <!-- การประมวลผลข้อมูลส่วนบุคคล  -->
              <p class="title is-4">Personal Data Processing</p>
              <p>
                The collection of personal information is done with the
                objective, scope and use of lawful and fair methods to collect
                and store information as well as collecting and storing limited
                personal information only as necessary to the service or
                services by any other electronic means under the company's
                objectives only. In this regard, the company will proceed with
                the information owner's acknowledgment and consent via SMS
                (short message service) or according to the method of the
                company. Our company may collect your personal information
                regarding your interests and services which may consist of race,
                religion or philosophy, your health information, biological
                information, disability, disability, identity or any other
                information that may be useful in service. In this regard, the
                aforementioned proceedings, our company will ask for your
                consent before collecting, unless:
                <br />
                <br />
                1. Lawful proceeding such as Personal Data Protection Act,
                Electronic Transactions Act, Telecommunications Business Act,
                Money Laundering Prevention and Suppression Act, Civil and
                Criminal Code, Code of Civil and Criminal Procedure, etc.
                <br /><br />

                2. For your benefit And requesting consent cannot be made at
                that time. <br /><br />

                3. Necessary for the legitimate interests of the company or of
                other persons or juristic persons other than the company.
                <br /><br />

                4. Necessary to prevent or stop the danger to a person's life,
                body or health. <br /><br />

                5. Necessary for the fulfillment of the contract in which the
                personal data owner is a contract party or to use in the
                processing of the request of the personal data owner before
                entering into that contract <br /><br />

                6. To achieve objectives relating to the preparation of
                historical documents or archives, for the benefit of the public
                or for research, statistical preparation which provides
                appropriate preventive measures.
              </p>

              <br />
              <!-- การเก็บรักษาและระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล -->
              <p class="title is-4">
                Retention and Duration of Personal Data Retention
              </p>
              <p>
                We will retain your personal information for a reasonable period
                of time as reasonably necessary in order to achieve the
                objectives for which we received the personal data. And to
                comply with legal obligations and regulations. However, we may
                keep your personal information longer if necessary in accordance
                with applicable law.
              </p>
              <br />
              <!-- การโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ -->
              <p class="title is-4">
                Transferring Your Personal Information to Other Countries
              </p>
              <p>
                We may disclose or transfer your personal information to third
                parties, service providers that are overseas which the
                destination country may have or may not have an equal level of
                personal data protection. However, we will take steps and
                measures to ensure that your personal information is transferred
                securely and that the person receiving it has the appropriate
                level of personal data protection or in other cases as required
                by law. And we will ask for your consent before transfer your
                personal information to different countries , if necessary to
                comply with the law.
              </p>
              <br />
              <!-- ความปลอดภัยของข้อมูล -->
              <p class="title is-4">
                Data Security
              </p>
              <p>
                We guarantee that all stored information will be stored safely.
                We protect your personal information by:
                <br />
                <br />

                1. Restrict access to personal information <br /><br />

                2. Provide technological methods to prevent unauthorized login
                <br /><br />

                3. Destroy your personal information for security purposes when
                such information is no longer needed for legal and business
                purposes <br /><br />

                4. We use 256 bit SSL encryption technology (Security Sockets
                Layer) when processing financial details.The 256-bit SSEL
                encryption technology is expected to take 2 trillion years to
                break the code. And it is an industry standard as well.<br /><br />

                If you have a reason to believe that your privacy has been
                violated by us, please contact us via the email listed below.<br /><br />

                Your password is a key for your service account. Please use
                numbers, characters, or symbols. And do not share our password
                with others. If you share your password with others, you will be
                responsible for various actions that has been done on your
                behalf or through your service account and as a result. If you
                are unable to control your password, you may not be able to
                control your personal information or other information that you
                have sent to us. You may have to accept any legal acts made in
                your name. Therefore, if your password is revealed or is no
                longer confidential for any reason or you have a reason to
                believe that the password is revealed or is no longer
                confidential, you should contact us and change your password. We
                remind you to log out of your account and log in to the browser
                every time you use a public computer.
              </p>
              <br />
              <!-- สิทธิของเจ้าของข้อมูล  -->
              <p class="title is-4">
                Rights of Data Owners
              </p>
              <p>
                You have the right to take the following actions<br /><br />
                (1) Right to withdraw consent: You have the right to withdraw
                consent to the processing of personal data that you have given
                us consent throughout the duration of your personal information
                with us. <br /><br />
                (2) Right of access: You have the right to access your personal
                information and ask us to make a copy of that personal data for
                you. As well as asking us to disclose the acquisition of
                personal information that you did not give us consent to.
                <br /><br />
                (3) Right to rectification: You have the right to ask us to
                correct inaccuracies or add incomplete information.<br /><br />
                (4) Right to erasure: You have the right to ask us to delete
                your data for some reason.
                <br /><br />
                (5) Right to restriction of processing: You have the right to
                suspend the use of your personal information for some reason.
                <br /><br />
                (6) Right to data portability: You have the right to transfer
                your personal data you have given us to another data controller
                or yourself for some reason.<br /><br />
                (7) Right to object: You have the right to object to the
                processing of your personal data for some reason.
              </p>
              <br />
              <!-- การเพิกถอนความยินยอม -->
              <p class="title is-4">
                Revocation of Consent
              </p>
              <p>
                You may submit objections to the use or disclosure of your
                personal information for various businesses or for the purposes
                specified above to us at any time by contacting us via the email
                specified below. Please note that if you submit an objection to
                the use or disclosure of your personal information for various
                businesses or for the purposes specified above depends on the
                circumstances of the objection, we may not be in a position to
                deliver products or services to you or fulfill your contract. We
                reserve the legal rights and remedies expressly in the event
                that we are unable to deliver goods or services to you or comply
                with your contract arising out of such objections.
              </p>
              <br />
              <!-- การอัพเดทหรือปรับเปลี่ยนข้อมูลส่วนตัวของท่าน -->
              <p class="title is-4">
                Updating or Modifying Your Personal Information
              </p>
              <p>
                You can update or modify your personal information at any time
                by logging into your service account on the our e-commerce
                platform. If you do not have an account with us, you may update
                or modify your personal information by contacting us via the
                email specified below.
              </p>
              <br />
              <!-- คุกกี้ (Cookies) -->
              <p class="title is-4">
                Cookies
              </p>
              <p>
                We or our authorized service providers may use cookies, web
                beacons or other programs similar to data storage to help us
                provide better services, faster, safer, and for your privacy
                when you use the service and/or enter the e-commerce platform.
                <br />
                <br />
                When you visit our servers, we automatically remember and save
                information that your browser sends when you enter our website.
                This information may include:
                <br />
                <br />
                1. IP Address of your computer.<br /><br />
                2. The type of your browser.<br /><br />
                3. The page of the website you accessed before you enter the
                e-commerce platform.<br /><br />
                4. The page of the website you visit on the e-commerce
                platform.<br /><br />
                5. The amount of time you spend viewing the said website
                products or information you searched for in the e-commerce
                platform, visited time, date, and other statistical data.<br /><br />
                6. These data are collected for analysis and evaluation to help
                us develop the e-commerce platform. Services and products we
                supply in order to be able to better serve you.
                <br />
                <br />
                <b>Cookies</b> is a small text file data (Usually only letters
                and numbers) that is placed on the memory of your browser or on
                your device when you visit a website or browse for messages.
                This program helps us to remember a specific device or browser,
                help us to quickly create content that suits your personal
                interests, and help make the service and the e-commerce platform
                more convenient and beneficial to you.
                <br />
                <br />
                <b>Web beacons </b> is a small graphic image that may be
                included in the service or on the e-commerce platform. This
                program helps us count the number of users who have visited
                various websites so that we can understand your needs and
                interests better.
              </p>
              <br />
              <!-- นโยบายคุ้มครองข้อมูลส่วนบุคคลของ website อื่น -->
              <p class="title is-4">
                Privacy Policy of Other Websites
              </p>
              <p>
                This Privacy Policy use only for our service and our website use
                only. If you have clicked a link to another website (even via
                the channels on our website), You must study and comply with the
                privacy policies listed on that website separately from ours.
              </p>
              <br />
              <!-- การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล  -->
              <p class="title is-4">
                Changes to Personal Data Protection Policies
              </p>
              <p>
                We will review the privacy policy on a regular basis to comply
                with the guidelines and relevant laws and regulations. If the
                privacy policy is changed, we will notify you by updating the
                information on our website as soon as possible. Currently, the
                privacy policy was last reviewed on June 1st, 2020
              </p>
              <br />
              <!-- ช่องทาง  -->
              <p class="title is-4">
                Channels
              </p>
              <p>
                Blushing Owl Studio Co.,Ltd <br />
                18/93 Soi KooBon 27, KooBon Road, Kwang Tahrang, Ket Bangken,
                Bangkok 10220 <br />
                Tel. (+66)84-347-0099 <br />
                Line ID : BlushingOwlStudio <br />
                WeChat : BlushingOwlStudio <br />
                Instagram : BlushingOwlStudio <br />
                Facebook : Blushing Owl Studio <br />
                Email : admin@blushingowlstudio.com <br />
                Website : www.blushingowlstudio.com
              </p>
            </section>
          </div>
          <!-- END : PRIVACY POLICY CONTENT -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style></style>
